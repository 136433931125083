






































































.values {
    .valuesContent {
        margin-top: 0.4rem;

        @media (max-width:767px) {
            margin-top: 0;
        }

        .title1 {
            color: #ff1932;
            font-size: 0.3rem;
            margin: 0.6rem 0;
            font-weight: bold;

            @media (max-width:767px) {
                margin: 0.3rem 0;
            }
        }

        .content {
            display: flex;
            flex-wrap: wrap;
            text-align: justify;

            .content1 {
                padding: 0.37rem 0.32rem;
                background: #333333;
                width: 6.4rem;
                // height: 1.8rem;
                color: #ffffff;
                margin: 0 0.2rem 0.2rem 0;

                @media (max-width: 767px) {
                    width: 100%;
                    height: auto;
                    margin: 0 0 0.2rem 0;
                }

                .title {
                    font-size: 0.24rem;
                    margin-bottom: 0.17rem;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                .text {
                    font-size: 0.2rem;
                    line-height: 2;
                    // overflow: hidden;
                    // display: -webkit-box;
                    // -webkit-line-clamp: 2;
                    // -webkit-box-orient: vertical;
                    // height: 0.6rem;

                }
            }

            .content2 {
                background: #ff1932;
                margin-right: 0rem;
            }

            .content3 {
                background: #eeeeee;
                color: #333333;
                margin: 0 0.2rem 0.2rem 0;

                @media (max-width: 767px) {
                    margin: 0 0 0.2rem 0;
                }
            }

            .content4 {
                background: #ffb9a0;
                color: #333333;
                margin-right: 0rem;
            }
        }
    }
}
